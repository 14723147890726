import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Share, ShareIcon, ShareNotification } from "./styled";
interface Props {
  text: string;
  onCopy?: Function;
}

const ShareButton: React.FC<Props> = ({ text, onCopy }) => {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setLinkCopied(false), 5000);
  }, [linkCopied]);

  const shareClickHandler = () => {
    setLinkCopied(true);
  };

  return (
    <Share onClick={shareClickHandler}>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <ShareIcon />
      </CopyToClipboard>
      {linkCopied && <ShareNotification>{`Link Copied !`}</ShareNotification>}
    </Share>
  );
};

export default ShareButton;
