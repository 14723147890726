import idx from "idx";
import { find, findIndex, get, groupBy, isEmpty, without } from "lodash";
import { Dispatch } from "redux";
import * as API from "src/api";
import { captureTopContent } from "src/api";
import Socket from "src/helpers/Socket";
import Utility from "src/helpers/utility";
import { IReduxState } from "../reducers";
import { AreaType } from "../types/areas";
import Types, { ExhibitDataType } from "../types/exhibit";
import ExploredTypes from "../types/explored";
import LocationTypes, { LocationType } from "../types/locations";
import { setCurrentMenuId } from "./app";
import { setActiveLanguageFromDevice } from "./language";

const CACHED_TIME =
  process.env.REACT_APP_ENV === "development" ? 2 * 60 : 5 * 24 * 3600;

//
export const swipeExhibit = (exhibitId: string, locationId?: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({
      type: Types.SWIPED_EXHIBIT_LOADING_REQUEST,
      payload: exhibitId,
    });
    // @ts-ignore
    await dispatch(getExhibit(exhibitId, false, locationId));
    dispatch({
      type: Types.SWIPED_EXHIBIT_LOADING_COMPLETE,
      payload: exhibitId,
    });
  } catch (error) {}
};

const addSecretExhibitsToLocation = (
  locationDetails: LocationType,
  getState
) => {
  const newLocation = { ...locationDetails };
  const state: IReduxState = getState();
  const locationId = idx(newLocation, x => x._id.$oid);
  const areas = idx(newLocation, x => x.areas) || [];
  // why is there a separate list of secret exhibits? Why are we doing all this monkey business? Bad developer, bad!
  const secretExhibits = Object.values(state.topics.secretExhibits || {});
  const exhibitsOfLoc = groupBy(secretExhibits, "locationId")[locationId];
  if (exhibitsOfLoc && exhibitsOfLoc.length > 0) {
    // loop over all exhibits in the secretExhibits list (which arent full exhibits, just a short versions of them, stashed in 10 places to make you work hard for your money)
    for (let index = 0; index < exhibitsOfLoc.length; index++) {
      const exhibit = exhibitsOfLoc[index];
      const exhibitId = idx(exhibit, x => x.data._id.$oid);
      const exhibitAreaId = idx(exhibit, x => x.data.areaId);
      const area = find(areas, { _id: { $oid: exhibitAreaId } });
      const areaIndex = findIndex(areas, { _id: { $oid: exhibitAreaId } });
      const exhibitOrder = idx(area, x => x.exhibitOrder) || [];

      // check if exhibit already exists in the area
      const exhibitAlreadyExist = find(area.exhibits, {
        _id: { $oid: exhibitId },
      });
      if (!exhibitAlreadyExist) {
        area.exhibits.push(exhibit.data);
      }

      //now we have to sort the exhibits in the area
      //loop over exhibitOrder and create a new temp exhibits list that is an ordered copy of area.exhibits, then replace the old list with the new ordered one
      let newExhibits = [];
      for (let i = 0; i < exhibitOrder.length; i++) {
        const exhibitId = exhibitOrder[i];
        const exhibit = find(area.exhibits, { _id: { $oid: exhibitId } });
        if (exhibit) {
          newExhibits.push(exhibit);
        }
      }
      // update the area with the new ordered exhibits
      areas[areaIndex].exhibits = newExhibits;
      // update the location with the updated areas
      newLocation.areas = areas;
    }
  }
  return newLocation as LocationType;
};

const handleLocationResponse = (
  dispatch,
  locationDetails,
  organizationId,
  getState
) => {
  const location = locationDetails;
  const areas = get(location, "areas", []);
  // Sort Areas based on AreaOrder
  const areaOrder = get(location, "areaOrder", []);

  let orderedAreas = areaOrder.map(areaId => {
    return find(areas, { _id: { $oid: areaId } });
  });
  if (areaOrder.length <= 0) {
    orderedAreas = areas;
  }
  orderedAreas = without(orderedAreas, undefined, null, [undefined]);
  const filteredAreas = orderedAreas.filter(
    area => area.exhibitOrder.length > 0
  );
  // this makes a new copy of location object, so we're not updating the state tree directly by mutating this object, fyi
  let updatedLocation = {
    ...location,
    areas: filteredAreas,
    organizationId,
  };

  // in case user has scanned secret topics from this location earlier
  // then here we will add secret exhibits back to location -> areas -> exhibit
  updatedLocation = addSecretExhibitsToLocation(updatedLocation, getState);
  // Iterate through all areas and sort exhibit if required and update areas
  let updatedAreasData = {};
  for (let index = 0; index < filteredAreas.length; index++) {
    const areaData = filteredAreas[index];
    const areaId = get(areaData, "_id.$oid");
    updatedAreasData = {
      ...updatedAreasData,
      [areaId]: { data: areaData },
    };
  }

  // update location data
  dispatch({
    type: LocationTypes.UPDATE_LOCATION_AND_AREAS,
    payload: {
      location: {
        id: get(location, "_id.$oid"),
        data: updatedLocation,
      },
      areas: updatedAreasData,
    },
  });
};

const handleWhiteLabelData = (dispatch, location) => {
  const whiteLabelData = {} as any;
  whiteLabelData.backgroundImage = location.mobileBackground
    ? location.mobileBackground
    : "";
  whiteLabelData.logo = location.mobileLogo ? location.mobileLogo : "";
  whiteLabelData.color = location.mobileTextColor
    ? location.mobileTextColor
    : "";
  // Update the data for white labeling
  dispatch({ type: Types.SET_WHITE_LABELING, payload: { whiteLabelData } });
};

export const getExhibit = (
  exhibitId: string,
  fromScanned: boolean = false,
  currentLocationId?: string
) => async (dispatch: Dispatch, getState) => {
  try {
    const state: IReduxState = getState();
    const activeLanguage = idx(state, x => x.language.activeLanguage);
    const deviceLanguage = idx(state, x => x.language.deviceLanguage);
    const currentExhibitViewsBy = idx(state, x => x.app.currentExhibitViewsBy);
    let locationId = idx(state, x => x.app.currentMenuId) || undefined;
    const exhibitData =
      idx(state, x => x.topics.exhibitDetails[exhibitId].data) ||
      ({} as ExhibitDataType);
    const isConnected = idx(state, x => x.app.connectedToInternet);
    let res: ExhibitDataType;
    let isExhibitAlreadyCached = false;
    let isLocationAlreadyCached = false;
    let locationDetails: LocationType;

    if (!isConnected) {
      Utility.AlertBox("Please connect to internet to view topic");
      return;
    }

    dispatch({ type: Types.GET_EXHIBIT_REQUEST, payload: { id: exhibitId } });

    // check whether we have exhibitData or not
    if (!isEmpty(exhibitData)) {
      // if we have already exhibit data
      res = exhibitData;
      isExhibitAlreadyCached = true;

      // then update cached exhibit data asynchronous
      // @ts-ignore
      dispatch(updateCachedExhibit(exhibitId, res));
    } else {
      // otherwise call api to get data
      res = await API.getExhibitContent(exhibitId);
    }

    const organizationId = idx(res, x => x.organizationId);
    const exhibitAreaId = idx(res, x => x.areaId) || "";
    const exhibitLocationId =
      idx(state, x => x.areas.areas[exhibitAreaId].data.locationId) || "";
    const exhibitLocationData =
      idx(state, x => x.location.locations[exhibitLocationId].data) ||
      ({} as LocationType);

    // effectively pinning the page session to the first app they hit, helps with provider accounts
    if (!locationId) {
      dispatch(setCurrentMenuId(idx(res, x => x.locationId)));
      locationId = idx(res, x => x.locationId);
    }

    // check whether we have location data or not
    if (!isEmpty(exhibitLocationData)) {
      isLocationAlreadyCached = true;
      locationDetails = exhibitLocationData;
      // then update cached location data
      // @ts-ignore
      dispatch(updateCachedLocationData(exhibitId, organizationId, locationId));
    } else {
      // if user is visiting exhibit using swipe then send location id also, so we get correct location details in case of provider topic
      const reqLocationId = !!currentLocationId ? currentLocationId : undefined;
      // otherwise do api call
      const locationRes = await API.getExhibitLocationsDetails(
        exhibitId,
        reqLocationId
      );
      locationDetails = get(locationRes, "location") || {};
    }

    const location = locationDetails;

    // checking if active language is set
    // or if the active language is supported
    // or if this is the first time loading this location
    if (
      !activeLanguage ||
      !location.supportedLanguages.includes(activeLanguage) ||
      !isLocationAlreadyCached
    ) {
      // setting active language to the device language if supported, otherwise using the locations's default language
      dispatch(
        setActiveLanguageFromDevice(
          location.supportedLanguages.includes(deviceLanguage)
            ? deviceLanguage
            : location.defaultLanguage
        )
      );
    }

    if (!isLocationAlreadyCached) {
      // if location is not already cached
      // if location data is new then do some redux actions
      handleLocationResponse(
        dispatch,
        locationDetails,
        organizationId,
        getState
      );
    }

    if (res.status === 3 || res.status === 4) {
      const secretTopic = {
        name: res.name,
        _id: res._id,
        exhibitImage: res.exhibitImage,
        exhibitImageThumb: res.exhibitImageThumb,
        status: res.status,
      } as ExhibitDataType;

      let areas = location?.areas || ([] as AreaType[]);
      let area = find(areas, { _id: { $oid: exhibitAreaId } });
      let areaIndex = findIndex(areas, { _id: { $oid: exhibitAreaId } });
      // check if exhibit already exists in the area
      const exhibitAlreadyExist = find(area.exhibits, {
        _id: { $oid: exhibitId },
      });
      // if not in area, add it, unordered
      if (!exhibitAlreadyExist) {
        area.exhibits.push(secretTopic);
      }

      //now we have to sort the exhibits in the area
      //loop over *exhibitOrder* and create a new temp exhibits list that is an ordered copy of area.exhibits, then replace the old list with the new ordered one
      let newExhibits = [];
      for (let i = 0; i < area.exhibitOrder.length; i++) {
        const exhibitId = area.exhibitOrder[i];
        const exhibit = find(area.exhibits, { _id: { $oid: exhibitId } });
        //add exhibits in order
        if (exhibit) {
          newExhibits.push(exhibit);
        }
      }
      // update the area with the new ordered exhibits
      area.exhibits = newExhibits;
      // update areas with the updated area
      location.areas[areaIndex] = area;
    }

    // update exhibit data
    dispatch({
      type: Types.GET_EXHIBIT_SUCCESS,
      payload: {
        data: res,
        locationId: idx(location, x => x._id.$oid),
        id: exhibitId,
        isExhibitAlreadyCached,
        isSecret: res.status === 3 || res.status === 4,
      },
    });

    Socket.onViewContent({
      areaId: exhibitAreaId,
      exhibitId,
      locationId: idx(location, x => x._id.$oid),
      organizationId,
    });

    await captureTopContent({
      areaId: exhibitAreaId,
      exhibitId,
      locationId: idx(location, x => x._id.$oid),
      organizationId,
      viewsBy: currentExhibitViewsBy,
    });

    // handleWhiteLabelData(dispatch, location);

    // Set exhibit as explored exhibit
    dispatch({
      type: ExploredTypes.SET_VISITED_EXHIBIT,
      payload: {
        exhibitId,
        locationId: idx(location, x => x._id.$oid),
        locationTitle: idx(location, x => x.name),
        areaId: idx(res, x => x.areaId),
      },
    });
  } catch (error) {
    dispatch({
      type: Types.GET_EXHIBIT_ERROR,
      payload: { id: exhibitId, error: error.toString() },
    });
    dispatch({ type: Types.FINDING_EXHIBIT_ID, payload: false });
    Utility.AlertBox(`Oops! Looks like this content is currently unavailable`);
  }
};

export const updateCachedExhibit = (
  exhibitId: string,
  cachedExhibitData: ExhibitDataType
) => async (dispatch, getState) => {
  try {
    const state: IReduxState = getState();
    const cachedDataModifiedTime = idx(
      cachedExhibitData,
      x => x.modifiedDate.$date.$numberLong
    );
    const exhibitLastFetchedtime =
      idx(state, x => x.topics.exhibitDetails[exhibitId].lastFetchedTime) ||
      Date.now();
    const FIVE_DAYS_IN_SECS = CACHED_TIME;
    const lastFetchedTimeDifference =
      (Date.now() - exhibitLastFetchedtime) / 1000;

    const res: ExhibitDataType = await API.getExhibitContent(exhibitId);
    const comingDataModifiedTime = idx(
      res,
      x => x.modifiedDate.$date.$numberLong
    );

    if (
      Number(comingDataModifiedTime) > Number(cachedDataModifiedTime) ||
      lastFetchedTimeDifference >= FIVE_DAYS_IN_SECS
    ) {
      // if upcoming data time is latest then update the data in redux
      // OR last fetched time is greater that 5 days , then update data
      dispatch({
        type: Types.UPDATE_CACHED_EXHIBIT_DATA,
        payload: {
          data: res,
          id: exhibitId,
        },
      });
    }
  } catch (error) {}
};

export const updateCachedLocationData = (
  exhibitId: string,
  organizationId: string,
  currentLocationId?: string
) => async (dispatch, getState) => {
  try {
    const locationRes = await API.getExhibitLocationsDetails(
      exhibitId,
      !!currentLocationId ? currentLocationId : undefined
    );
    const locationDetails: LocationType = get(locationRes, "location") || {};
    handleLocationResponse(dispatch, locationDetails, organizationId, getState);
    handleWhiteLabelData(dispatch, locationDetails);
  } catch (error) {}
};

export const removeTemporaryTopicsFromSecretExhibits = () => async (
  dispatch,
  getState
) => {
  const state: IReduxState = getState();
  const secretExhibits = state.topics.secretExhibits;
  if (Object.keys(secretExhibits).length !== 0) {
    Object.keys(secretExhibits).forEach(key => {
      if (secretExhibits[key].data && secretExhibits[key].data.status === 4) {
        delete secretExhibits[key];
      }
    });
  }
};

// NOT USED

// export const scanQRCodeAndfindExibit = (scannedUrl: string) => async(
//   dispatch: Dispatch,
//   getState
// ) => {
//   try {
//     const state: IReduxState = getState();
//     const isConnected = idx(state, x => x.app.connectedToInternet);
//     if (isConnected) {
//       dispatch({ type: Types.FINDING_EXHIBIT_ID, payload: true });
//       const resp = await API.getExhibitId(scannedUrl);
//       const exhibitId = get(resp, "data.exhibit_id");
//       dispatch({ type: Types.FINDING_EXHIBIT_ID, payload: false });
//       return exhibitId;
//     } else {
//       alert("Please Connect To Internet To View Topic");
//     }
//   } catch (error) {
//     dispatch({ type: Types.FINDING_EXHIBIT_ID, payload: false });
//     Utility.AlertBox(`Oh no! This isn't a Liiingo iiiCode.`);
//     return null;
//   }
// };
