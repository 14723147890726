import idx from "idx";
import * as React from "react";
import { XCircle } from "react-feather";
import { Portal } from "react-portal";
import { connect } from "react-redux";
import { LanguageEnum } from "src/helpers/constants";
import * as LanguageActions from "src/store/actions/language";
import { IReduxState } from "src/store/reducers";
import {
  CloseButton,
  Container,
  LanguageButton,
  LanguageCodeText,
  LanguageSelectWrapper,
  LanguageTitle,
  LanguageWrapper,
  Modal,
  ModalTitle,
  Props,
} from "./styled";

interface InternalProps {
  setActiveLanguage?: Function;
  supportedLanguages?: string[];
  activeLanguage?: string;
}

const LanguageSelect: React.FC<Props & InternalProps> = props => {
  const { setActiveLanguage, supportedLanguages, activeLanguage } = props;
  const [visible, setVisible] = React.useState(false);

  const size = window.screen.width > 600 ? "lg" : "sm";

  const handleClick = () => setVisible(!visible);

  return supportedLanguages ? (
    <>
      <LanguageSelectWrapper>
        <LanguageButton onClick={handleClick}>
          <LanguageCodeText variant={activeLanguage.length > 2 ? "xs" : "sm"}>
            {activeLanguage}
          </LanguageCodeText>
        </LanguageButton>
      </LanguageSelectWrapper>

      {visible && (
        <Portal>
          <Modal>
            <CloseButton onClick={handleClick}>
              <XCircle size={40} />
            </CloseButton>
            <ModalTitle variant={size}>Select a Language</ModalTitle>
            <Container>
              {supportedLanguages.map(language => (
                <LanguageWrapper key={language}>
                  <LanguageButton
                    variant={size}
                    onClick={() => {
                      setActiveLanguage(language);
                      handleClick();
                    }}
                  >
                    <LanguageCodeText variant={size}>
                      {language}
                    </LanguageCodeText>
                  </LanguageButton>
                  <LanguageTitle variant={size}>
                    {LanguageEnum[language]}
                  </LanguageTitle>
                </LanguageWrapper>
              ))}
            </Container>
          </Modal>
        </Portal>
      )}
    </>
  ) : null;
};

export default connect<{}, {}, Props, {}>(
  (state: IReduxState, ownProps: Props) => {
    const id = ownProps.locationId;
    const supportedLanguages = idx(
      state,
      x => x.location.locations[id].data.supportedLanguages
    );
    const activeLanguage = idx(state, x => x.language.activeLanguage);
    return {
      supportedLanguages,
      activeLanguage,
    };
  },
  {
    setActiveLanguage: LanguageActions.setActiveLanguage,
  }
)(LanguageSelect);
