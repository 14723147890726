import idx from "idx";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import defaultImage from "src/assets/images/default-header.png";
import history from "src/helpers/history";
import urls from "src/helpers/urls";
import { IReduxState } from "src/store/reducers";
import { LocationType } from "src/store/types";
import LanguageSelect from "../LanguageSelect";

import {
  BannerTopIconWrapper,
  BannerWrapper,
  CompanyName,
  Container,
  Overlay,
  Props,
  Title,
  TitleLogo,
  TitleLogoWrapper,
} from "./styled";
import { updateLocationWithoutTemporaryExhibit } from "src/store/actions/locations";
interface InternalProps {
  location?: LocationType;
  children?: React.ReactNode;
  image?: string;
  useImageFromLocation?: boolean;
}

const Banner: React.FC<Props & InternalProps> = props => {
  const dispatch = useDispatch();
  const { location, children, locationId, image, useImageFromLocation } = props;
  const logo =
    idx(location, x => x.headerLogoThumb) || idx(location, x => x.headerLogo);
  const title = idx(location, x => x.name);
  const companyName = idx(location, x => x.companyName);

  let bannerImage = image;
  if (useImageFromLocation) {
    bannerImage =
      idx(location, x => x.backgroundImageThumb) ||
      idx(location, x => x.backgroundImage);
  }

  const handleMenuNavigation = () => {
    dispatch(updateLocationWithoutTemporaryExhibit(locationId));
    history.push(urls.location(locationId));
  };

  return (
    <BannerWrapper image={bannerImage || defaultImage}>
      <Container>
        <BannerTopIconWrapper>
          <LanguageSelect locationId={locationId} />
        </BannerTopIconWrapper>
        <TitleLogoWrapper
          onClick={() => {
            handleMenuNavigation();
          }}
        >
          {logo && <TitleLogo logo={logo} />}
          <div>
            <CompanyName>{companyName}</CompanyName>
            <Title>{title}</Title>
          </div>
        </TitleLogoWrapper>
      </Container>
      {children}
      {/* show gradient overlay if there is custom image */}
      {bannerImage && <Overlay />}
    </BannerWrapper>
  );
};

Banner.defaultProps = {
  useImageFromLocation: true,
};

export default connect<{}, {}, Props, {}>(
  (state: IReduxState, ownProps: Props) => {
    const id = ownProps.locationId;
    const location =
      idx(state, x => x.location.locations[id].data) || ({} as LocationType);
    return {
      location,
    };
  }
)(Banner);
